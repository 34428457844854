.dashboard-page {
  display: flex;
  margin: 100px auto;
  justify-content: space-between;
  padding: 20px 80px;
  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
  }
  .page__left {
    display: flex;
    flex-direction: column;
    width: 400px;
    padding: 20px 10px;
    border: 1px solid #e3e3e3;
    box-shadow: 1px 1px 9px rgba(0, 0, 0, 0.06);
    border-radius: 25px;
    @media screen and (max-width: 600px) {
      width: 100%;
      margin-bottom: 20px;
    }
    .left__top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      .top__content {
        .content__heading {
          @include text(15px, '', 600);
          color: $SECONDRY;
          margin-bottom: 6px;
        }
        .content__username {
          @include text(11px, '', 500);
          color: $SECONDRY;
        }
      }
      .top__action {
        @include text(13px, '', 500);
        color: $THIRD;
        height: 45px;
        width: 130px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $THIRD;
        border-radius: 10px;
        cursor: pointer;
      }
    }
    .left__middle {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      .middle__content {
        display: flex;
        flex-direction: column;
        margin-bottom: 5px;
        .content__name {
          @include text(11px, '', 500);
          color: $SECONDRY;
        }
        .content__qty {
          @include text(11px, '', 600);
          color: $SECONDRY;
        }
      }
    }
    .left__bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      background: #e3e3e3;
      border-radius: 10px;
      padding: 10px;
      .bottom__content {
        display: flex;
        flex-direction: column;
        margin-bottom: 5px;
        .content__name {
          @include text(11px, '', 500);
          color: $SECONDRY;
        }
        .content__qty {
          @include text(11px, '', 600);
          color: $SECONDRY;
        }
      }
      .bottom__restake {
        @include text(13px, '', 500);
        color: $THIRD;
        cursor: pointer;
      }
      .bottom__claim {
        @include text(11px, '', 500);
        height: 30px;
        width: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $THIRD;
        border: 1px solid $THIRD;
        border-radius: 10px;
        cursor: pointer;
      }
    }
    .left__stake {
      @include text(12px, '', 500);
      height: 40px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $THIRD;
      border: 1px solid $THIRD;
      border-radius: 10px;
      margin-bottom: 20px;
      cursor: pointer;
    }
    .left__unstake {
      @include text(11px, '', 500);
      color: lightcoral;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      margin-bottom: 20px;
      cursor: pointer;
    }
  }
  .page__right {
    display: flex;
    flex-direction: column;
    .right__top {
      display: flex;
      @media screen and (max-width: 600px) {
        width: 100%;
      }

      .top__box1 {
        display: flex;
        flex-direction: column;
        width: 250px;
        height: 120px;
        padding: 20px 10px;
        background: #e3e3e3;
        border-radius: 10px;
        margin-right: 20px;
        @media screen and (max-width: 600px) {
          width: 50%;
          height: 150px;
        }

        .box1__title {
          @include text(18px, '', 500);
          color: $SECONDRY;
        }
        .box1__amount {
          @include text(23px, '', 600);
          color: $SECONDRY;
        }
        .box1__price {
          @include text(12px, '', 500);
          color: $SECONDRY;
        }
      }
      .top__box2 {
        display: flex;
        flex-direction: column;
        width: 250px;
        height: 120px;
        padding: 20px 10px;
        background: #e3e3e3;
        border-radius: 10px;
        @media screen and (max-width: 600px) {
          width: 50%;
          height: 150px;
        }
        .box2__title {
          @include text(18px, '', 500);
          color: $SECONDRY;
        }
        .box2__rewards {
          @include text(23px, '', 600);
          color: $SECONDRY;
        }
      }
    }
    .right__bottom {
      display: flex;
      flex-direction: column;
      width: 520px;
      padding: 20px 10px;
      background: #e3e3e3;
      border-radius: 10px;
      margin-top: 20px;
      @media screen and (max-width: 600px) {
        width: 100%;
      }
      .bottom__heading {
        @include text(15px, '', 600);
        color: $SECONDRY;
        margin-bottom: 10px;
      }
      .bottom {
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: 600px) {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        .bottom__content {
          display: flex;
          flex-direction: column;
          margin-bottom: 5px;
          @media screen and (max-width: 600px) {
            margin-bottom: 10px;
          }
          .content__name {
            @include text(11px, '', 500);
            color: $SECONDRY;
          }
          .content__stats {
            @include text(14px, '', 600);
            color: $SECONDRY;
            @media screen and (max-width: 600px) {
              text-align: center;
            }
          }
        }
      }
    }
  }
}
