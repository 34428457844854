.app {
  background-color: #ffffff;
}
@font-face {
  font-family: 'Acumin Variable Concept';
  src: url('./assets/fonts/AcuminVariableConcept.otf') format('opentype');
}
body,
html {
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Acumin Variable Concept';
  overflow-x: hidden;
}
