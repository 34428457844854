//==========================
//   APP BUTTON
//==========================
.app--button {
  @include text(16px, '', '');
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 15px;
  color: $WHITE;
  outline: none;
  border: 1px solid white;
  background-color: $SECONDRY;
  border-radius: 4px;
  @media only screen and (max-width: 992px) {
    font-size: 14px;
    height: 34px;
    padding: 12px 15px;
    border-radius: 6px;
  }
}

.app__heading {
  @include text(40px, '', 900);
  color: $PRIMARY;
  margin: 30px 0px;
  text-align: center;
}
.app__description {
  @include text(22px, '', 600);
  color: $PRIMARY;
  line-height: 30px;
  margin-bottom: 10px;
  text-align: center;
}
.border-bottom {
  border-bottom: 2px solid $WHITE !important;
}

//=========================
//    PACKAGE CARD
//=========================
.package-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  .card__img {
    width: 200px;

    .wrapper__video {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }
  .card__name {
    @include text(18px, '', 600);
    color: $SECONDRY;
    margin: 5px 0px 8px;
  }
  .card__description {
    @include text(11px, '', 400);
    color: $SECONDRY;
    margin: 5px 0px 10px;
  }
  .card__price {
    background: $THIRD;
    border-radius: 4px;
    @include text(16px, '', 600);
    color: $SECONDRY;
    width: 100%;
    text-align: center;
  }
}
