.page-faqs {
  background-color: transparent;
  padding: 70px 130px 30px;
  @media screen and (max-width: 900px) {
    padding: 40px 20px;
  }
}

.faqs-sec {
  padding: 20px 0;
  position: relative;
}
.faqs-sec > .title-heading {
  font-size: 26px;
  font-weight: bold;
  text-transform: uppercase;
  color: #213745;
  margin-bottom: 15px;
}

.faqs-main {
  padding-top: 25px;
  margin: 0;
  position: relative;
  width: 100%;
  @media all and (max-width: 900px) {
    width: 100%;
    padding-top: 0px;
  }
  .accordian__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 900px) {
      display: flex;
      flex-direction: column;
    }
    .accordion {
      position: relative;
      z-index: 1;
      width: 45%;
      @media screen and (max-width: 900px) {
        width: 100%;
      }
    }
  }
}
.faqs-main:before {
  position: absolute;
  top: -50px;
  left: -70px;
  width: 229px;
  height: 227px;

  content: '';
  z-index: 1;
}
.faqs-main:after {
  position: absolute;
  bottom: -70px;
  right: -70px;
  width: 185px;
  height: 185px;

  content: '';
}

.faqs-main .accordion-item {
  margin-bottom: 15px;
  border-radius: 5px;
  background: transparent;
  border: none;
}
.faqs-main .accordion-item .accordion-button {
  @include text(18px, '', 500);
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px 18px 20px 20px;
  letter-spacing: 1px;
  background-color: #e3e3e3;
  text-align: left;
  border: 0;
  border-radius: 4px;

  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    border-radius 0.15s ease;
  box-shadow: none !important;
}

.accordion-button:nth-child(3n + 1) {
  border-top-right-radius: 20px !important;
}

// .accordion-button:nth-child(3n + 2) {
//   border-top-left-radius: 20px !important;
// }
.faqs-main .accordion-item .accordion-button:not(.collapsed) {
  color: $BLACK;
  background-color: #e3e3e3;

  box-shadow: none !important;
  border-radius: 5px;
  border-top-right-radius: 20px;
}
.faqs-main .accordion-item .accordion-button::after {
  transform: rotate(-360deg);
  background-image: url(../../assets/down.png);
}

.faqs-main .accordion-button:not(.collapsed)::after {
  background-image: url(../../assets/down.png);
  transform: rotate(-180deg);
}
.faqs-main .accordion-body {
  padding: 20px 18px 20px 20px;
  background-color: #e3e3e3;
}
.faqs-main .accordion-body p {
  font-size: 14px;
  color: $BLACK;
  line-height: 26px;
  letter-spacing: 1px;
  margin: 0;
  padding: 0;
}
