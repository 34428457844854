.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 20px 80px;
  .header__left {
    cursor: pointer;
    .left__logo {
      width: 150px;
    }
  }
  .header__right {
    display: flex;
    align-items: center;
    .right__text {
      @include text(19px, '', 600);
      margin: 0px;
      padding: 0px 15px;
      text-align: center;
      cursor: pointer;
      text-decoration: none;
      outline: none;
      border: none;
      background: none;
      border-right: 2px solid $BLACK;
      &:last-child {
        border-right: none;
      }
    }
  }
}
.btn-success {
  color: #fff;
  @include text(17px, '', 600);
  margin: 0px 20px;
  background-color: transparent;
  border-color: $SECONDRY;
}
.color__Black {
  color: $BLACK !important;
}

//===========================
//======MOBILE HEADER =======
//===========================

.mobile-header {
  background-color: $SECONDRY;
  padding: 20px;
  display: flex;
  z-index: 13;
  justify-content: space-between;
  .header-logo {
    height: 115px;
    object-fit: cover;
  }
  .header-hamberg {
    width: 26px;
  }
}

.mbl-menu {
  background: $SECONDRY;
  box-shadow: 0px 0 4px #a2a1a1;
  top: 0;
  height: auto;
}
.mbl-menu-overlay {
  background: #fff !important;
}

.menu-items {
  .mobile__top {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .header-logo {
      height: 115px;
      object-fit: cover;
    }
    .header-hamberg {
      width: 30px;
    }
  }
  .menu-buttons {
    margin-top: 50px;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .page-links {
      @include text(18px, 0.13em, 700, uppercase);
      color: $WHITE;
      padding: 20px 0;
      text-decoration: none;
      outline: none;
      border: none;
      background: none;
    }
    .social-link {
      .link {
        margin-right: 30px;
        .link__icon {
          width: 35px;
        }
      }
    }
    .banner__button {
      margin-top: 50px;
      width: 250px;
      height: 90px;
      display: flex;
      justify-content: center;
      align-items: center;
      @include text(25px, '', bold, uppercase);
      color: $SECONDRY;
      border: none;
      outline: none;
      background-color: $BLACK;
    }
  }
}

.bm-burger-button {
  position: fixed;
  width: 26px;
  height: 21px;
  top: 35px;
  right: 20px;
  padding: 10px;
  z-index: 1 !important;
}

//====================================
//      CONNECT MODAL
//====================================
.request-modal-overlay {
  position: fixed;
  z-index: 12;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: rgba(0, 0, 0, 0.7);
  .request-modal {
    position: absolute;
    // top: 60px !important;
    left: 0px;
    right: 0px;
    // bottom: 40px !important;
    background-color: #e3e3e3;
    border-radius: 12px;
    outline: none;

    width: 482px;
    padding: 16px;

    margin: auto;
    transform: translate(-50%, -50%);
    inset: 50% auto auto 50%;
    @media screen and (max-width: 600px) {
      height: auto;
      width: 80%;
      padding: 20px;
      overflow: hidden;
      padding-bottom: 0px;
      top: 400px;
    }
  }
}

//====================================
// LOGIN MODAL
//====================================

.login-modal-overlay {
  position: fixed;
  z-index: 12;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: rgba(0, 0, 0, 0.7);
  .login-modal {
    position: absolute;
    left: 0px;
    right: 0px;
    background-color: white;
    border-radius: 12px;
    outline: none;
    width: 400px;
    height: auto;
    padding: 25px 20px;
    margin: auto;
    transform: translate(-50%, -50%);
    inset: 50% auto auto 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 600px) {
      height: auto;
      width: 90%;
      padding: 15px;
      overflow: hidden;
      top: 400px;
    }
    .form__group {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      .colabe-input {
        @include text(15px, '', 600);
        background-color: #e3e3e3;
        border-radius: 3px;
        width: 90%;
        border: none;
        outline: none;
        height: 40px;
        text-indent: 15px;
        border-top-right-radius: 20px;
        margin-top: 14px;
        margin: 10px auto;
      }
    }
    .forgot__password {
      width: 90%;
      @include text(13px, '', 500);
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 5px;
      cursor: pointer;
      text-decoration: underline;
      @media screen and (max-width: 900px) {
        margin-top: 0px;
      }
    }
    .form__login {
      @include text(26px, '', 700);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      @media screen and (max-width: 900px) {
        margin-top: 15px;
      }
      .login__arrow {
        width: 30px;
        height: 30px;
        margin-left: 15px;
        @media screen and (max-width: 900px) {
          width: 25px;
          height: 25px;
        }
      }
    }
  }
}

.request-email {
  width: 420px;
  margin: auto;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
  .request--cancel {
    display: none;
    @media screen and (max-width: 600px) {
      margin-right: -11px;
      display: flex;
      justify-content: flex-end;
      cursor: pointer;
      margin-bottom: 9px;
    }
  }
  .modal__input {
    @include text(13px, '', bold);
    box-shadow: none;
    border-radius: 3px;
    border: 1px solid #bdbcbc;
    background-color: #fff;
    padding: 0 10px;
    outline: 0;
    height: 35px;
    width: 375px;
    margin: 0px auto 50px;
    @media screen and (max-width: 600px) {
      width: 100%;
      margin-bottom: 30px;
    }
  }
  .request--button {
    width: 375px;
    font-weight: normal;
    text-transform: initial;
    font-style: normal;
    font-size: 16px;
    letter-spacing: '';
    color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 15px;
    color: #ffffff;
    outline: none;
    border: 1px solid white;
    background-color: $SECONDRY;
    border-radius: 4px;
    cursor: pointer;
    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }
}

.connect-modal {
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }
  .modal--metamask {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include text(24px, '', 700);
    margin-top: 0.5em;
    padding: 12px 15px;
    outline: none;
    border: none;
    outline: none;
    background-color: transparent;
    .text {
      font-size: 18px;
      margin: 0.333em 0px;
    }
  }
  .modal--metamask:hover {
    background-color: #d4bc4d;
  }
}

//====================================
// SIGNATURE MODAL
//====================================

.signature-modal-overlay {
  position: fixed;
  z-index: 12;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: rgba(0, 0, 0, 0.7);
  .signature-modal {
    position: absolute;
    left: 0px;
    right: 0px;
    background-color: white;
    border-radius: 12px;
    outline: none;
    width: 612px;
    height: 90%;
    overflow-y: scroll;
    padding: 25px 20px;
    margin: auto;
    transform: translate(-50%, -50%);
    inset: 50% auto auto 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 600px) {
      height: 90%;
      width: 90%;
      padding: 15px;
      overflow: scroll;
      top: 382px;
    }
    .signature {
      position: absolute;
      top: 0;
      width: 100%;
      canvas {
        width: 100% !important;
        height: 600px !important;
      }
      .pdf--actions {
        position: absolute;
        top: 7px;
        left: 30%;
        .pager {
          display: flex;
          list-style: none;
          .next {
            margin-left: 10px;
          }
        }
      }
      .signature__section {
        position: absolute;
        margin-top: -54px;
        width: 100%;
        .signature__text {
          @include text(13px, 0.2px, 600);
          color: red;
          margin-left: 30px;
        }
        canvas {
          width: 100% !important;
          height: 91px !important;
        }

        .form__group {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 20px 30px;
          @media screen and (max-width: 900px) {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            padding: 0px;
          }
          input::-webkit-input-placeholder {
            @include text(15px, '', 600);
          }
          input:-moz-placeholder {
            color: $BLACK;
          }
          .colabe-input {
            @include text(15px, '', 600);
            background-color: #bdbcbc;
            border-radius: 3px;
            width: 40%;
            border: none;
            outline: none;
            height: 35px;
            text-indent: 15px;
            border-top-right-radius: 20px;
            @media screen and (max-width: 900px) {
              width: 90%;
              margin-top: 15px;
            }
          }
        }
        .form__register {
          @include text(24px, '', 700);
          display: flex;
          justify-content: center;
          cursor: pointer;
          @media screen and (max-width: 900px) {
            margin-top: 15px;
          }
          .register__arrow {
            width: 30px;
            height: 30px;
            margin-left: 15px;
          }
          &:hover {
            color: #d4bc4d;
          }
        }
      }
    }

    .form__verify-otp {
      @include text(24px, '', 700);
      display: flex;
      justify-content: center;
      cursor: pointer;
      @media screen and (max-width: 900px) {
        margin-top: 15px;
      }
      .otp__arrow {
        width: 30px;
        height: 30px;
        margin-left: 15px;
      }
      &:hover {
        color: #d4bc4d;
      }
    }
  }

  .signature-modal::-webkit-scrollbar {
    width: 4px;
    height: 0px;
  }

  .signature-modal::-webkit-scrollbar-track {
    border-radius: 10px;
    background: none;
  }

  .signature-modal::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #bdbcbc;
  }
}

//====================================
// OTP MODAL
//====================================

.otp-modal-overlay {
  position: fixed;
  z-index: 12;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: rgba(0, 0, 0, 0.7);
  .otp-modal {
    position: absolute;
    left: 0px;
    right: 0px;
    background-color: white;
    border-radius: 12px;
    outline: none;
    width: auto;
    height: auto;
    padding: 25px 30px;
    margin: auto;
    transform: translate(-50%, -50%);
    inset: 50% auto auto 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 600px) {
      overflow: hidden;
      top: 400px;
    }

    .form__verify-otp {
      @include text(24px, '', 700);
      display: flex;
      justify-content: center;
      cursor: pointer;
      margin-top: 20px;
      @media screen and (max-width: 900px) {
        margin-top: 15px;
        font-size: 22px;
      }
      .otp__arrow {
        width: 30px;
        height: 30px;
        margin-left: 15px;
      }
      &:hover {
        color: #d4bc4d;
      }
    }
  }
}

//====================================
// LOADING MODAL
//====================================

.loading-modal-overlay {
  position: fixed;
  z-index: 12;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: rgba(0, 0, 0, 0.3);
  .loading-modal {
    position: absolute;
    left: 0px;
    right: 0px;
    background-color: transparent;
    border-radius: 12px;
    outline: none;
    width: auto;
    height: auto;
    padding: 25px 20px;
    margin: auto;
    transform: translate(-50%, -50%);
    inset: 50% auto auto 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

//====================================
//           FOOTER
//====================================
.footer {
  background: #fff;
  padding: 20px 80px;
  position: relative;
  @media screen and (max-width: 900px) {
    padding: 20px 20px 40px;
  }
  .checkbox {
    width: 90%;
    margin: auto;
    padding-bottom: 20px;
    border-bottom: 2px solid #ced0d1;
    .checkbox__container {
      display: flex;
      align-items: center;
      position: relative;
      cursor: pointer;
      font-size: 11px;
      padding-left: 27px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      .checkbox__text {
        @include text(20px, '', 600);
        color: #000000;
      }
      input {
        display: flex;
        width: 20px;
        height: 20px;
        margin-left: 5px;
      }
    }
  }
  .footer__links {
    width: 70%;
    margin: auto;
    display: flex;
    padding-top: 20px;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 900px) {
      width: 90%;
      display: flex;
      flex-wrap: wrap;
      row-gap: 10px;
    }
    a {
      padding: 4px;
      background: silver;
      border-radius: 100%;
      display: flex;
      margin-right: 30px;
      cursor: pointer;
      @media screen and (max-width: 900px) {
        margin-right: 15px;
      }
      img {
        width: 40px;
        height: 40px;
        object-fit: contain;
      }
    }
  }
}

//====================================
// REGISTRATION FORM
//====================================
.registration-form {
  background-color: #e3e3e3;
  padding: 40px 130px;
  border-top-right-radius: 40px;
  @media screen and (max-width: 900px) {
    margin: 20px 0px;
    padding: 10px 20px 20px;
  }
  .form__group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 40px;
    @media screen and (max-width: 900px) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 0px;
    }
    input::-webkit-input-placeholder {
      @include text(15px, '', 600);
    }
    input:-moz-placeholder {
      color: $BLACK;
    }
    .colabe-input {
      @include text(15px, '', 600);
      background-color: #fff;
      border-radius: 3px;
      width: 40%;
      border: none;
      outline: none;
      height: 35px;
      text-indent: 15px;
      border-top-right-radius: 20px;
      @media screen and (max-width: 900px) {
        width: 90%;
        margin-top: 15px;
      }
    }
    .form__checkbox {
      display: flex;
      align-items: center;
      position: relative;
      cursor: pointer;
      font-size: 11px;
      padding-left: 0px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      @media only screen and (max-width: 992px) {
        padding-top: 15px;
        margin-left: 5%;
      }
      .checkbox__text {
        @include text(26px, '', 700);
        color: $BLACK;
        @media screen and (max-width: 900px) {
          font-size: 18px;
        }
      }
      input {
        display: flex;
        width: 20px;
        height: 20px;
        margin-left: 5px;
        @media only screen and (max-width: 992px) {
          width: 17px;
          height: 17px;
        }
      }
    }
    input[type='file'] {
      display: none;
    }
    .group__file-field {
      background-color: #fff;
      border-radius: 3px;
      width: 40%;
      height: 35px;
      position: relative;
      border-top-right-radius: 20px;
      padding: 6px 12px;
      @media screen and (max-width: 900px) {
        width: 90%;
        margin-top: 15px;
      }
      cursor: pointer;

      .custom-file-upload {
        position: absolute;
        @include text(15px, '', 600);
        color: #d4bc4d;
        display: flex;
        align-items: center;
        border: none;
        outline: none;
        text-indent: 2px;
        top: 9px;
      }
      img {
        position: absolute;
        width: 19px;
        height: 22px;
        left: 100px;
        top: 8px;
      }
      .group__file-preview {
        position: absolute;
        top: -28px;
        display: flex;
        align-items: center;
        background: white;
        border-radius: 10px;
        padding: 0px 8px;
        left: 1px;
        .preview__name {
          @include text(14px, '', 500);
          max-width: 175px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          word-wrap: break-word;
          color: #d4bc4d !important;
        }
        .preview--cancel {
          @include text(17px, '', 700);
          margin-left: 15px;
          color: #d4bc4d !important;
          cursor: pointer;
        }
      }
    }

    .form__register {
      @include text(24px, '', 700);
      display: flex;
      justify-content: center;
      cursor: pointer;
      @media screen and (max-width: 900px) {
        margin-top: 15px;
      }
      .register__arrow {
        width: 30px;
        height: 30px;
        margin-left: 15px;
      }
      &:hover {
        color: #d4bc4d;
      }
    }
  }
}
.pointer-event-none {
  pointer-events: none;
}


//====================================
// REGISTRATION FORM
//====================================
.dashboard {
  background-color: #f2f2f2;
  //position: absolute;
  padding: 40px 130px;
  // border-top-right-radius: 40px;
  @media screen and (max-width: 900px) {
    margin: 20px 0px;
    padding: 10px 20px 20px;
  }

  
  .dashboard__eth {
    display: flex;
    //position:absolute;
    justify-content: space-between;
    align-items: center;
    background-color: #000000;
    margin-top: 40px;
    padding: 40px 50px;

    @media screen and (max-width: 900px) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      row-gap: 20px;
      padding: 20px 0px;
    }
    .eth__content {
      @include text(20px, '', 600);
      color: white;
    }
  }
  .dashboard__bnb {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #cecece;
    margin-top: 40px;
    padding: 40px 50px;
    @media screen and (max-width: 900px) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0px;
    }
    .bnb__left {
      @media screen and (max-width: 900px) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 0px;
      }
      .left__content {
        @include text(20px, '', 600);
        margin-top: 10px;
      }
      .left--withdraw {
        @include text(24px, '', 700);
        display: flex;
        cursor: pointer;
        text-decoration: none;
        margin-top: 10px;
        @media screen and (max-width: 900px) {
          margin-top: 15px;
        }
        .withdraw__arrow {
          width: 30px;
          height: 30px;
          margin-left: 15px;
        }
        &:hover {
          color: #d4bc4d;
        }
      }
    }
    .bnb__right-content {
      @include text(24px, '', 700);
    }
  }
}
