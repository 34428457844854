.home-page {
  width: 100%;
  .page__intro {
    background-color: #f1eada;
    padding: 100px 0px 100px 130px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 900px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 50px 20px;
    }

    .intro__content {
      width: 100%;
      margin: auto;
      .content__heading1 {
        @include text(55px, '', 800);
        line-height: 55px;
        @media screen and (max-width: 900px) {
          font-size: 30px;
        }
      }
      .content__heading2 {
        @include text(70px, '', 800);
        letter-spacing: 3px;
        line-height: 73px;
        @media screen and (max-width: 900px) {
          font-size: 38px;
          line-height: 45px;
        }
      }
      .content__sub-heading {
        @include text(30px, '', 700);
        margin-top: 25px;
        margin-bottom: 0px;

        @media screen and (max-width: 900px) {
          font-size: 17px;
          margin-top: 15px;
        }
      }
    }
    .intro_pic {
      margin-left: 60px;
      width: 400px;
      height: 200px;
      .list__img {
        width: 100%;
        height: 100%;
      }
      @media screen and (max-width: 900px) {
        width: 80%;
        object-fit: cover;
        margin-left: 0;
        margin-top: 20px;
      }
    }
  }
  .page__describe {
    padding: 50px 130px;
    @media screen and (max-width: 900px) {
      padding: 40px 20px;
    }
    .describe__subheading {
      @include text(28px, '', 600);
      margin: 20px 0px;
      @media screen and (max-width: 900px) {
        font-size: 18px;
      }
    }
    .describe__types {
      display: flex;
      align-items: flex-start;
      margin-top: 20px;

      .types__img {
        width: 44px;
        height: 44px;
        object-fit: cover;
      }
      .types__content {
        display: flex;
        flex-direction: column;
        margin-left: 15px;

        .content__name {
          @include text(24px, '', 700);
          @media screen and (max-width: 900px) {
            font-size: 22px;
          }
        }
        .content__para {
          @include text(22px, '', 500);
          @media screen and (max-width: 900px) {
            font-size: 18px;
          }
        }
      }
    }
  }
  .page_details {
    padding: 70px 130px;
    margin: auto;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 900px) {
      padding: 40px 20px;
    }
    .details__table {
      padding-top: 10px;
      .table__heading {
        display: flex;
        justify-content: space-between;
        .heading__text:nth-child(1) {
          width: 16%;
        }
        .heading__text {
          @include text(22px, '', 800);
          width: 21%;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          margin-bottom: 0px;
          @media screen and (max-width: 900px) {
            font-size: 14px;
            font-weight: 600;
            text-align: center;
          }
        }
      }
      .heading__border {
        border: 1px solid #bb7e1f;
        opacity: 1;
        margin: 10px 0px;
        @media screen and (max-width: 900px) {
          margin: 8px 0px;
        }
      }
      .table__description {
        display: flex;
        justify-content: space-between;
        .description__text:nth-child(1) {
          width: 16%;
        }
        .description__text {
          @include text(15px, '', 500);
          color: black;
          width: 21%;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          border-right: 1px solid #bb7e1f;
          padding-bottom: 10px;
          margin-bottom: 0px;
          white-space: break-spaces;
          @media screen and (max-width: 900px) {
            font-size: 12px;
            font-weight: 500;
          }
        }
        .description__text:last-child {
          border-right: none;
        }
      }
      .content__description {
        @include text(15px, '', '');
        color: $PRIMARY;
        padding-left: 0px;
      }
    }
  }
  .page__started {
    background: #dbb94c;
    padding: 50px;
    .started--link {
      width: 360px;
      margin: auto;
      color: #575757 !important;
      background: $PRIMARY;
      border-color: $PRIMARY;
      font-size: 25px;
      font-weight: 600;
      padding: 12px 60px;
      cursor: pointer;
      @media screen and (max-width: 900px) {
        font-size: 18px;
        width: 80%;
        padding: 12px 30px;
      }
    }
  }
}
.website__heading-title {
  @include text(40px, 1px, 800);
  margin: 0px;
  @media screen and (max-width: 900px) {
    font-size: 35px;
  }
}
